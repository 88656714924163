// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-calendula-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-customer-blog/templates/calendula/Template.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-customer-blog-templates-calendula-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceCategoryTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-category-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/ServiceItemTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-service-item-template-jsx" */),
  "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx": () => import("./../../../../../node_modules/@bluefin/gatsby-plugin-fisherman-page-creator/templates/calendula/TeamMemberTemplate.jsx" /* webpackChunkName: "component---node-modules-bluefin-gatsby-plugin-fisherman-page-creator-templates-calendula-team-member-template-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-bridal-experiences-jsx": () => import("./../../../src/pages/bridal-experiences.jsx" /* webpackChunkName: "component---src-pages-bridal-experiences-jsx" */),
  "component---src-pages-careers-jsx": () => import("./../../../src/pages/careers.jsx" /* webpackChunkName: "component---src-pages-careers-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-extensions-jsx": () => import("./../../../src/pages/extensions.jsx" /* webpackChunkName: "component---src-pages-extensions-jsx" */),
  "component---src-pages-facial-waxing-jsx": () => import("./../../../src/pages/facial-waxing.jsx" /* webpackChunkName: "component---src-pages-facial-waxing-jsx" */),
  "component---src-pages-haircut-jsx": () => import("./../../../src/pages/haircut.jsx" /* webpackChunkName: "component---src-pages-haircut-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-makeup-jsx": () => import("./../../../src/pages/makeup.jsx" /* webpackChunkName: "component---src-pages-makeup-jsx" */),
  "component---src-pages-new-guests-jsx": () => import("./../../../src/pages/new-guests.jsx" /* webpackChunkName: "component---src-pages-new-guests-jsx" */),
  "component---src-pages-payment-plans-jsx": () => import("./../../../src/pages/payment-plans.jsx" /* webpackChunkName: "component---src-pages-payment-plans-jsx" */),
  "component---src-pages-policies-jsx": () => import("./../../../src/pages/policies.jsx" /* webpackChunkName: "component---src-pages-policies-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-smoothing-texture-jsx": () => import("./../../../src/pages/smoothing-texture.jsx" /* webpackChunkName: "component---src-pages-smoothing-texture-jsx" */),
  "component---src-pages-team-jsx": () => import("./../../../src/pages/team.jsx" /* webpackChunkName: "component---src-pages-team-jsx" */),
  "component---src-pages-welcome-experience-jsx": () => import("./../../../src/pages/welcome-experience.jsx" /* webpackChunkName: "component---src-pages-welcome-experience-jsx" */)
}

